.nav-menu {
  display: flex;
  justify-content: center;
}
.nav-menu a {
  text-decoration: none;
}
.nav-text-mobile {
  color: black;
}
.nav-text-mobile.clicked {
  color: #04aa6d;
  border-bottom: 2px solid #04aa6d;
}
.nav-text {
  color: black;
  position: relative;
  display: inline-block;
  /* padding-bottom: 5px; */
  /* Reserve space for the sliding border */
}
.nav-text::before {
  content: "";
  position: absolute;
  bottom: 0;
  left: 0;
  width: 0; /* Start with no width */
  height: 2px;
  background-color: #04aa6d; /* Border color */
  transition: width 0.3s ease-out; /* Transition for width */
}
.nav-text:hover {
  color: #04aa6d;
}
.nav-text.clicked {
  color: #04aa6d;
}
.nav-text-bottom-border {
  content: "";
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 2px;
  background-color: #04aa6d;
}

.nav-text:hover::before {
  width: 100%; /* Slide border from left to right on hover */
}
